<script setup lang="ts">
import { computed, ref } from 'vue'
import axios from 'axios'
import Cookies from 'js-cookie'
import { usePageStore } from '@voix/store/pageStore'

import { useI18n } from 'vue-i18n'

const props = defineProps<{
  fields: {
    formid?: {
      value: string
    }
  }
}>()

const { t } = useI18n({})

const showForm = ref(false)
const form = ref({
  formId: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  IsTravelAgent: 'No',
  TCAgree: 'No',
  Language_Code: 'EN',
  country: '',
})
const success = ref({
  show: false,
  message: 'success',
})
const error = ref({
  message: 'error',
})
const formInvalid = ref(false)
const firstNameInvalid = ref(false)
const lastNameInvalid = ref(false)
const emailInvalid = ref(false)
const countryInvalid = ref(false)
const shouldShowError = ref(false)
const agreeToTermsInvalid = ref(false)
const validationErrors = ref([])

const consentMessage = computed(() => {
  return form.value.country === 'CA'
    ? t('i-want-to-receive-electronic-communications-ca')
    : t('i-want-to-receive-electronic-communications')
})

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

const highlightStyle = computed(() => {
  const siteId = currentPage.value?.site_id
  if (siteId === 2 || siteId === 3)
    return { 'background-color': 'rgb(0, 189, 198)' }

  if (siteId === 5)
    return { 'background-color': '#30a298' }

  if (siteId === 8)
    return { 'background-color': '#662d91' }

  return {}
})

function initForm() {
  showForm.value = true
  const hidePopup = Cookies.get('hide-pop-up')
  if (hidePopup === 'true')
    showForm.value = false
}

function submit() {
  if (formValidates()) {
    const { $playa } = useNuxtApp()
    const instance = axios.create({})
    const formData = Object.assign({}, form.value)
    formData.formId = props.fields.formid ? props.fields.formid.value : ''

    instance
      .post(`${$playa.rest_api_base_url}/divinci/web-email-registration`, formData)
      .then(() => {
        showSuccess()
      })
      .catch(() => {
        showError(t('there-was-a-problem'))
      })
  }
}

function formValidates() {
  // reset
  formInvalid.value = false
  validationErrors.value = []

  if (form.value.TCAgree === 'No') {
    agreeToTermsInvalid.value = true
    formInvalid.value = true
  }
  else {
    agreeToTermsInvalid.value = false
  }
  if (form.value.firstName === null || form.value.firstName === '') {
    firstNameInvalid.value = true
    formInvalid.value = true
  }
  else {
    firstNameInvalid.value = false
  }
  if (form.value.lastName === null || form.value.lastName === '') {
    lastNameInvalid.value = true
    formInvalid.value = true
  }
  else {
    lastNameInvalid.value = false
  }
  if (form.value.country === '') {
    countryInvalid.value = true
    formInvalid.value = true
  }
  else {
    countryInvalid.value = false
  }
  if (!validEmail()) {
    emailInvalid.value = true
    formInvalid.value = true
  }
  else {
    emailInvalid.value = false
  }

  return formInvalid.value === false
}
function validEmail() {
  return /\S[^\s@]*@\S+\.\S+/.test(form.value.emailAddress)
}
function showError(msg: string) {
  error.value.message = msg
  shouldShowError.value = true
}
function showSuccess() {
  success.value.show = true
  success.value.message = t('thank-you')
  Cookies.set('hide-pop-up', 'true', { expires: 99999999 })

  const dataLayer = window.dataLayer

  try {
    dataLayer.push({
      event: 'newsletter',
    })
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}
function closeForm() {
  Cookies.set('hide-pop-up', 'true', { expires: 7 })
  showForm.value = false
}

function visibilityChanged(entry: IntersectionObserverEntry) {
  if (showForm.value === false && entry.intersectionRatio * 100 > 0)
    initForm()
}

form.value.Language_Code = currentPage.value?.language_code.toUpperCase()
  ? currentPage.value?.language_code.toUpperCase()
  : 'EN'

const hidePopup = Cookies.get('hide-pop-up')
if (hidePopup === 'true')
  showForm.value = false
defineSlice({
  name: { label: 'Sign Up Pop Up', group: 'global', layouts: ['*'] },
  tags: ['Form', 'Pop Up'],
  fields: { formid: { type: 'text', label: 'Form ID', name: 'formid' } },
  slots: [],
})
</script>

<template>
  <div>
    <span
      v-voix-visibility="{ callback: visibilityChanged, threshold: 1 }"
      class="h-[50px] bg-red-500"
    />
    <div v-if="showForm">
      <ClientOnly>
        <Teleport to="#portal-destination">
          <div v-if="showForm" key="signuppopup" class="fixed inset-0 z-100">
            <utilities-blocker @click="closeForm" />
            <div class="modal fixed z-50 shadow-lg wysiwyg text-white">
              <div class="flex flex-col md:flex-row relative max-w-[1024px] mx-auto">
                <!-- Close Button -->
                <div
                  class="absolute top-0 right-0 mt-2 mr-2 cursor-pointer"
                  @click="closeForm"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </div>

                <!-- Content -->
                <div
                  class="flex flex-col justify-center items-center p-4 pb-0 md:p-8 w-full md:w-1/2 bg-playa-turquoise text-center"
                  :style="highlightStyle"
                >
                  <p class="uppercase text-2xl md:text-4xl md:mt-14">
                    {{ $t("sign-up-to-save") }}
                  </p>
                  <p class="text-xl md:text-3xl font-thin my-0 md:my-4">
                    {{ $t("receive-via-email") }}
                  </p>
                  <img
                    src="https://playaresorts.com/imgs/pop-up-modal-icon.png"
                    class="mt-4 md:inline-block md:max-w-full max-w-32"
                  >
                </div>

                <!-- Form -->
                <div class="p-6 md:p-8 w-full md:w-1/2 bg-playa-dark-blue">
                  <form class="w-full">
                    <div v-if="success.show" v-cloak class="success">
                      <h6 class="uppercase text-sm font-bold mb-2">
                        Form successfully submitted!
                      </h6>
                      <p>{{ success.message }}</p>
                    </div>
                    <template v-if="!success.show">
                      <div class="flex">
                        <fieldset
                          class="md:mr-2 w-full mb-4 border-b-2 border-grey-lighter"
                        >
                          <label class="font-light mb-0 leading-tight">
                            {{ $t("first-name") }}
                            <span v-if="firstNameInvalid" class="ml-4 font-bold text-red">Required</span>
                          </label>
                          <input
                            v-model="form.firstName"
                            style="color: #7f91a3; padding: 0.5em"
                            type="text"
                            name="First Name"
                            placeholder="John"
                            class="w-full bg-playa-dark-blue text-white border-0"
                          >
                        </fieldset>
                        <fieldset
                          class="ml-2 md:mr-2 w-full mb-4 border-b-2 border-grey-lighter"
                        >
                          <label class="font-light mb-0 leading-tight">
                            {{ $t("last-name") }}
                            <span v-if="lastNameInvalid" class="ml-4 font-bold text-red">Required</span>
                          </label>
                          <input
                            v-model="form.lastName"
                            style="color: #7f91a3; padding: 0.5em"
                            type="text"
                            name="Last Name"
                            placeholder="Doe"
                            class="w-full bg-playa-dark-blue text-white border-0"
                          >
                        </fieldset>
                      </div>
                      <fieldset
                        class="md:mr-2 w-full mb-4 border-b-2 border-grey-lighter"
                      >
                        <label class="font-light mb-0 leading-tight">
                          Email
                          <span v-if="emailInvalid" class="ml-4 font-bold text-red">Valid Email Required</span>
                        </label>
                        <input
                          v-model="form.emailAddress"
                          style="color: #7f91a3; padding: 0.5em"
                          type="email"
                          name="Email"
                          placeholder="Email@example.com"
                          class="w-full bg-playa-dark-blue text-white border-0"
                        >
                      </fieldset>
                      <fieldset
                        class="md:mr-2 w-full mb-4 border-b-2 border-grey-lighter"
                      >
                        <label class="font-light mb-0 leading-tight">
                          {{ $t("country") }}
                          <span v-if="countryInvalid" class="ml-4 font-bold text-red">Required</span>
                        </label>
                        <select
                          v-model="form.country"
                          name="country"
                          style="color: #7f91a3; padding: 0.5em"
                          class="w-full required bg-playa-dark-blue text-white opacity-50 border-0"
                        >
                          <option value="" disabled>
                            {{ $t("select-country") }}
                          </option>
                          <option value="US">
                            United States
                          </option>
                          <option value="CA">
                            Canada
                          </option>
                          <option value="GB">
                            United Kingdom
                          </option>
                          <option value="AF">
                            Afghanistan
                          </option>
                          <option value="AX">
                            Aland Islands
                          </option>
                          <option value="AL">
                            Albania
                          </option>
                          <option value="DZ">
                            Algeria
                          </option>
                          <option value="AS">
                            American Samoa
                          </option>
                          <option value="AD">
                            Andorra
                          </option>
                          <option value="AO">
                            Angola
                          </option>
                          <option value="AI">
                            Anguilla
                          </option>
                          <option value="AQ">
                            Antarctica
                          </option>
                          <option value="AG">
                            Antigua &amp; Barbuda
                          </option>
                          <option value="AR">
                            Argentina
                          </option>
                          <option value="AM">
                            Armenia
                          </option>
                          <option value="AW">
                            Aruba
                          </option>
                          <option value="AU">
                            Australia
                          </option>
                          <option value="AT">
                            Austria
                          </option>
                          <option value="AZ">
                            Azerbaijan
                          </option>
                          <option value="BS">
                            Bahamas
                          </option>
                          <option value="BH">
                            Bahrain
                          </option>
                          <option value="BD">
                            Bangladesh
                          </option>
                          <option value="BB">
                            Barbados
                          </option>
                          <option value="BY">
                            Belarus
                          </option>
                          <option value="BE">
                            Belgium
                          </option>
                          <option value="BZ">
                            Belize
                          </option>
                          <option value="BJ">
                            Benin
                          </option>
                          <option value="BM">
                            Bermuda
                          </option>
                          <option value="BT">
                            Bhutan
                          </option>
                          <option value="BO">
                            Bolivia
                          </option>
                          <option value="BQ">
                            Bonaire
                          </option>
                          <option value="BA">
                            Bosnia And Herzegowina
                          </option>
                          <option value="BW">
                            Botswana
                          </option>
                          <option value="BV">
                            Bouvet Island
                          </option>
                          <option value="BR">
                            Brazil
                          </option>
                          <option value="IO">
                            British Indian Ocean Territory
                          </option>
                          <option value="VG">
                            British Virgin Islands
                          </option>
                          <option value="BN">
                            Brunei Darussalam
                          </option>
                          <option value="BG">
                            Bulgaria
                          </option>
                          <option value="BF">
                            Burkina Faso
                          </option>
                          <option value="BI">
                            Burundi
                          </option>
                          <option value="KH">
                            Cambodia
                          </option>
                          <option value="CM">
                            Cameroon
                          </option>
                          <option value="CV">
                            Cape Verde
                          </option>
                          <option value="KY">
                            Cayman Islands
                          </option>
                          <option value="CF">
                            Central African Republic
                          </option>
                          <option value="TD">
                            Chad
                          </option>
                          <option value="CD">
                            Channel Islands
                          </option>
                          <option value="CL">
                            Chile
                          </option>
                          <option value="CN">
                            China
                          </option>
                          <option value="CX">
                            Christmas Island
                          </option>
                          <option value="CC">
                            Cocos (keeling) Islands
                          </option>
                          <option value="CO">
                            Colombia
                          </option>
                          <option value="KM">
                            Comoros
                          </option>
                          <option value="CG">
                            Congo
                          </option>
                          <option value="CK">
                            Cook Islands
                          </option>
                          <option value="CR">
                            Costa Rica
                          </option>
                          <option value="CI">
                            Cote D'ivoire/ivory Coast
                          </option>
                          <option value="HR">
                            Croatia
                          </option>
                          <option value="CU">
                            Cuba
                          </option>
                          <option value="BW">
                            Curacoa
                          </option>
                          <option value="CY">
                            Cyprus
                          </option>
                          <option value="CZ">
                            Czech Republic
                          </option>
                          <option value="CI">
                            Cã´te D'ivoire
                          </option>
                          <option value="CD">
                            Democratic Republic Of The Congo
                          </option>
                          <option value="DK">
                            Denmark
                          </option>
                          <option value="DJ">
                            Djibouti
                          </option>
                          <option value="DM">
                            Dominica
                          </option>
                          <option value="DO">
                            Dominican Republic
                          </option>
                          <option value="TP">
                            East Timor
                          </option>
                          <option value="EC">
                            Ecuador
                          </option>
                          <option value="EG">
                            Egypt
                          </option>
                          <option value="SV">
                            El Salvador
                          </option>
                          <option value="EN">
                            England
                          </option>
                          <option value="GQ">
                            Equatorial Guinea
                          </option>
                          <option value="ER">
                            Eritrea
                          </option>
                          <option value="EE">
                            Estonia
                          </option>
                          <option value="ET">
                            Ethiopia
                          </option>
                          <option value="FO">
                            Faeroe Islands
                          </option>
                          <option value="FK">
                            Falkland Islands
                          </option>
                          <option value="ZZ">
                            Fictitious Points
                          </option>
                          <option value="FJ">
                            Fiji
                          </option>
                          <option value="FI">
                            Finland
                          </option>
                          <option value="FR">
                            France
                          </option>
                          <option value="GF">
                            French Guiana
                          </option>
                          <option value="PF">
                            French Polynesia
                          </option>
                          <option value="TF">
                            French Southern Territories
                          </option>
                          <option value="GA">
                            Gabon
                          </option>
                          <option value="GM">
                            Gambia
                          </option>
                          <option value="GE">
                            Georgia
                          </option>
                          <option value="DE">
                            Germany
                          </option>
                          <option value="GH">
                            Ghana
                          </option>
                          <option value="GI">
                            Gibralter
                          </option>
                          <option value="GR">
                            Greece
                          </option>
                          <option value="GL">
                            Greenland
                          </option>
                          <option value="GD">
                            Grenada
                          </option>
                          <option value="GP">
                            Guadeloupe
                          </option>
                          <option value="GU">
                            Guam
                          </option>
                          <option value="GT">
                            Guatemala
                          </option>
                          <option value="GG">
                            Guernsey
                          </option>
                          <option value="GN">
                            Guinea
                          </option>
                          <option value="GW">
                            Guinea-bissau
                          </option>
                          <option value="GY">
                            Guyana
                          </option>
                          <option value="HT">
                            Haiti
                          </option>
                          <option value="HM">
                            Heard And Mcdonald Islands
                          </option>
                          <option value="HN">
                            Honduras
                          </option>
                          <option value="HK">
                            Hong Kong
                          </option>
                          <option value="HU">
                            Hungary
                          </option>
                          <option value="IS">
                            Iceland
                          </option>
                          <option value="IN">
                            India
                          </option>
                          <option value="ID">
                            Indonesia
                          </option>
                          <option value="IR">
                            Iran
                          </option>
                          <option value="IQ">
                            Iraq
                          </option>
                          <option value="IE">
                            Ireland
                          </option>
                          <option value="IM">
                            Isle Of Man
                          </option>
                          <option value="IL">
                            Israel
                          </option>
                          <option value="IT">
                            Italy
                          </option>
                          <option value="JM">
                            Jamaica
                          </option>
                          <option value="JP">
                            Japan
                          </option>
                          <option value="JO">
                            Jordan
                          </option>
                          <option value="KZ">
                            Kazakstan
                          </option>
                          <option value="KE">
                            Kenya
                          </option>
                          <option value="KI">
                            Kiribati
                          </option>
                          <option value="KW">
                            Kuwait
                          </option>
                          <option value="KG">
                            Kyrgystan
                          </option>
                          <option value="LA">
                            Lao People's Democratic Republic
                          </option>
                          <option value="LV">
                            Latvia
                          </option>
                          <option value="LB">
                            Lebanon
                          </option>
                          <option value="LS">
                            Lesotho
                          </option>
                          <option value="LR">
                            Liberia
                          </option>
                          <option value="LY">
                            Libyan Arab Jamahiriya
                          </option>
                          <option value="LI">
                            Liechtenstein
                          </option>
                          <option value="LT">
                            Lithuania
                          </option>
                          <option value="LU">
                            Luxembourg
                          </option>
                          <option value="MO">
                            Macau
                          </option>
                          <option value="MK">
                            Macedonia (fyrom)
                          </option>
                          <option value="MG">
                            Madagascar
                          </option>
                          <option value="MW">
                            Malawi
                          </option>
                          <option value="MY">
                            Malaysia
                          </option>
                          <option value="MV">
                            Maldives
                          </option>
                          <option value="ML">
                            Mali
                          </option>
                          <option value="MT">
                            Malta
                          </option>
                          <option value="MH">
                            Marshall Islands
                          </option>
                          <option value="MQ">
                            Martinique
                          </option>
                          <option value="MR">
                            Mauritania
                          </option>
                          <option value="MU">
                            Mauritius
                          </option>
                          <option value="YT">
                            Mayotte
                          </option>
                          <option value="MX">
                            Mexico
                          </option>
                          <option value="FM">
                            Micronesia
                          </option>
                          <option value="MD">
                            Moldova
                          </option>
                          <option value="MC">
                            Monaco
                          </option>
                          <option value="MN">
                            Mongolia
                          </option>
                          <option value="MS">
                            Montserrat
                          </option>
                          <option value="MA">
                            Morocco
                          </option>
                          <option value="MZ">
                            Mozambique
                          </option>
                          <option value="MM">
                            Myanmar
                          </option>
                          <option value="NA">
                            Namibia
                          </option>
                          <option value="NR">
                            Nauru
                          </option>
                          <option value="NP">
                            Nepal
                          </option>
                          <option value="NL">
                            Netherlands
                          </option>
                          <option value="AN">
                            Netherlands Antilles
                          </option>
                          <option value="NC">
                            New Caledonia
                          </option>
                          <option value="NZ">
                            New Zealand
                          </option>
                          <option value="NI">
                            Nicaragua
                          </option>
                          <option value="NE">
                            Niger
                          </option>
                          <option value="NG">
                            Nigeria
                          </option>
                          <option value="NU">
                            Niue
                          </option>
                          <option value="XX">
                            No Country
                          </option>
                          <option value="NF">
                            Norfolk Island
                          </option>
                          <option value="KR">
                            North Korea
                          </option>
                          <option value="MP">
                            Northern Mariana Islands
                          </option>
                          <option value="NO">
                            Norway
                          </option>
                          <option value="OM">
                            Oman
                          </option>
                          <option value="PK">
                            Pakistan
                          </option>
                          <option value="PW">
                            Palau
                          </option>
                          <option value="PS">
                            Palestine, State Of
                          </option>
                          <option value="PA">
                            Panama
                          </option>
                          <option value="PG">
                            Papua New Guinea
                          </option>
                          <option value="PY">
                            Paraguay
                          </option>
                          <option value="PE">
                            Peru
                          </option>
                          <option value="PH">
                            Philippines
                          </option>
                          <option value="PN">
                            Pitcairn
                          </option>
                          <option value="PL">
                            Poland
                          </option>
                          <option value="PT">
                            Portugal
                          </option>
                          <option value="PR">
                            Puerto Rico
                          </option>
                          <option value="QA">
                            Qatar
                          </option>
                          <option value="RE">
                            Reunion
                          </option>
                          <option value="RO">
                            Romania
                          </option>
                          <option value="RU">
                            Russia
                          </option>
                          <option value="RW">
                            Rwanda
                          </option>
                          <option value="SX">
                            Saint Maarten
                          </option>
                          <option value="WS">
                            Samoa
                          </option>
                          <option value="SM">
                            San Marino
                          </option>
                          <option value="ST">
                            Sao Tome And Principe
                          </option>
                          <option value="SA">
                            Saudi Arabia
                          </option>
                          <option value="SN">
                            Senegal
                          </option>
                          <option value="SQ">
                            Serbia And Montenegro
                          </option>
                          <option value="SC">
                            Seychelles
                          </option>
                          <option value="SL">
                            Sierra Leone
                          </option>
                          <option value="SG">
                            Singapore
                          </option>
                          <option value="SK">
                            Slovakia
                          </option>
                          <option value="SI">
                            Slovenia
                          </option>
                          <option value="SB">
                            Solomon Islands
                          </option>
                          <option value="SO">
                            Somalia
                          </option>
                          <option value="ZA">
                            South Africa
                          </option>
                          <option value="GS">
                            South Georgia And The South Sandwich Islands
                          </option>
                          <option value="KP">
                            South Korea
                          </option>
                          <option value="ES">
                            Spain &amp; Canary Islands
                          </option>
                          <option value="LK">
                            Sri Lanka
                          </option>
                          <option value="SH">
                            St. Helena
                          </option>
                          <option value="KN">
                            St. Kitts And Nevis
                          </option>
                          <option value="LC">
                            St. Lucia
                          </option>
                          <option value="PM">
                            St. Pierre And Miquelon
                          </option>
                          <option value="VC">
                            St. Vincent And The Grenadines
                          </option>
                          <option value="SD">
                            Sudan
                          </option>
                          <option value="SR">
                            Suriname
                          </option>
                          <option value="SJ">
                            Svalbard And Jan Mayen Islands
                          </option>
                          <option value="SZ">
                            Swaziland
                          </option>
                          <option value="SE">
                            Sweden
                          </option>
                          <option value="CH">
                            Switzerland
                          </option>
                          <option value="SY">
                            Syrian Arab Republic
                          </option>
                          <option value="TW">
                            Taiwan
                          </option>
                          <option value="TJ">
                            Tajikistan
                          </option>
                          <option value="TZ">
                            Tanzania
                          </option>
                          <option value="TH">
                            Thailand
                          </option>
                          <option value="TG">
                            Togo
                          </option>
                          <option value="TK">
                            Tokelau
                          </option>
                          <option value="TO">
                            Tonga
                          </option>
                          <option value="TT">
                            Trinidad And Tobago
                          </option>
                          <option value="TN">
                            Tunisia
                          </option>
                          <option value="TR">
                            Turkey
                          </option>
                          <option value="TM">
                            Turkmenistan
                          </option>
                          <option value="TC">
                            Turks And Caicos Islands
                          </option>
                          <option value="TV">
                            Tuvalu
                          </option>
                          <option value="VI">
                            U.s. Virgin Islands
                          </option>
                          <option value="UG">
                            Uganda
                          </option>
                          <option value="UA">
                            Ukraine
                          </option>
                          <option value="AE">
                            United Arab Emirates
                          </option>
                          <option value="UM">
                            United States Minor Outlying Islands
                          </option>
                          <option value="UY">
                            Uruguay
                          </option>
                          <option value="UZ">
                            Uzbekistan
                          </option>
                          <option value="VU">
                            Vanuatu
                          </option>
                          <option value="VA">
                            Vatican City State
                          </option>
                          <option value="VE">
                            Venezuela
                          </option>
                          <option value="VN">
                            Viet Nam
                          </option>
                          <option value="WF">
                            Wallis And Futuna Islands
                          </option>
                          <option value="EH">
                            Western Sahara
                          </option>
                          <option value="YE">
                            Yemen
                          </option>
                          <option value="YU">
                            Yugoslavia
                          </option>
                          <option value="ZR">
                            Zaire
                          </option>
                          <option value="ZM">
                            Zambia
                          </option>
                          <option value="ZW">
                            Zimbabwe
                          </option>
                        </select>
                      </fieldset>
                      <div class="mb-2">
                        <fieldset>
                          <label
                            class="flex font-normal normal-case text-2xs leading-normal"
                          >
                            <input
                              v-model="form.IsTravelAgent"
                              name="I am a Travel Agent"
                              type="checkbox"
                              value="The Royal"
                              class="mr-2"
                              true-value="Yes"
                              false-value="No"
                            >
                            <span
                              style="width: 95%; font-weight: normal"
                              class="text-xs font-weight-normal"
                            >
                              {{ $t("i-am-a-travel-agent") }}
                            </span>
                          </label>
                        </fieldset>
                        <fieldset>
                          <span
                            v-if="agreeToTermsInvalid"
                            class="text-xs font-bold text-red"
                          >YOU MUST AGREE TO TERMS</span>
                          <label
                            class="flex font-normal normal-case text-2xs leading-tight"
                          >
                            <input
                              v-model="form.TCAgree"
                              type="checkbox"
                              class="mr-2"
                              true-value="Yes"
                              false-value="No"
                            >
                            <span
                              style="width: 95%; font-weight: normal"
                              class="text-xs font-weight-normal"
                              v-html="consentMessage"
                            />
                          </label>
                        </fieldset>
                      </div>
                      <div>
                        <fieldset>
                          <input
                            type="button"
                            class="btn bg-playa-turquoise"
                            :style="highlightStyle"
                            :value="$t('subscribe')"
                            @click="submit"
                          >
                        </fieldset>
                      </div>
                    </template>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Teleport>
      </ClientOnly>
    </div>
  </div>
</template>

<style scoped>
.modal {
  left: 0;
  top: 5%;
  height: 90%;
  width: 100%;
  max-height: 100%;
  min-width: 100%;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (min-width: 600px) {
  .modal {
    left: 50%;
    top: 50%;
    height: initial;
    width: initial;
    max-height: 90%;
    min-width: 80%;
    margin: 1rem 0.5rem;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
